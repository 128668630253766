import React, { Component } from "react";
import { graphql, Link } from "gatsby";
import Typical from "react-typical";
import Seo from "../../components/seo";
import Navbar from "../../components/navbar";
// import styles from './Projects.module.scss';

const SubtitleAnimation = React.memo(
  () => {
    const steps = ["Articles...just getting started"];
    return <Typical steps={steps} wrapper="span" />;
  },
  (props, prevProp) => true
);

const Blogs = ({ data }) => {
  return (
    <>
      <Seo
        title="Blog"
        description="Blog articles"
        vercel={{ title: "My writings…and ramblings" }}
      />
      <article>
        <header>
          <Navbar backNavbar={true} />
          <h1>Blog</h1>
          <p className="subtitle">
            <SubtitleAnimation />
          </p>
        </header>
        <blockquote>
          <p>
            I love deadlines. I love the whooshing noise they make as they go
            by.
          </p>
          <footer>
            Douglas Adams, <cite>“The Salmon of Doubt”</cite>
          </footer>
        </blockquote>
        <section>
          {data.allMdx.nodes.map((project) => (
            <section key={project.id}>
              <h2>
                {project.frontmatter.publish ? (
                  <Link to={`${project.slug}`}>
                    {project.frontmatter.title}
                  </Link>
                ) : (
                  <>{project.frontmatter.title}</>
                )}
              </h2>
              <small>
                {project.frontmatter.date}
                {"—"}
                {project.fields.readingTime.text}
              </small>
              {project.frontmatter.publish ? (
                <p>
                  {project.frontmatter.abstract}{" "}
                  <small>
                    <Link to={`${project.slug}`}>Read more</Link> ☛
                  </small>
                </p>
              ) : (
                <p>
                  Project write-up coming soon…
                  {project.frontmatter.github_url ? (
                    <span>
                      see{" "}
                      <a
                        href={project.frontmatter.github_url}
                        title={project.frontmatter.title}
                      >
                        code
                      </a>
                    </span>
                  ) : (
                    ""
                  )}
                </p>
              )}
            </section>
          ))}
        </section>
      </article>
    </>
  );
};

export const pageQuery = graphql`
  query articlesQuery {
    allMdx(
      sort: { fields: frontmatter___date, order: DESC }
      filter: { frontmatter: { category: { eq: "blog" } } }
    ) {
      nodes {
        frontmatter {
          title
          github_url
          project_url
          abstract
          date(formatString: "MMMM D, YYYY")
          publish
          category
        }
        id
        slug
        fields {
          readingTime {
            text
          }
        }
      }
    }
  }
`;

export default Blogs;
